import lisaZC from '../../assets/lisa-zc/lisa-zc.png'

const LisaZc = () => {
    const aboutText1 = 'I was not the best at studying. I took some opportunities for granted, ' +
        'but, at those times, I knew little of how the world worked (not that I have figured it all out yet). ' +
        'I kind of wasted my opportunity at studying in Boston, but I think that the result of how things ' +
        'unfolded became to create something greater.'
    const aboutText2 = 'I think I am young, but when I was younger, I had a total misdirected fire ' +
        'inside of me which led me to do reckless stuff. I was forced to come back to Lima, and then I ' +
        'decided to redirect that energy into something for good. So as my friends, especially the Russian, ' +
        'also named Lisa, had an unrealistic illusion that I would come back to Boston, I was designing t-shirts ' +
        'for them. I went to ghettos to produce at the beginning, and I made friends with the people, their realities,' +
        ' and met their families. Here I started understanding more about the world. I had to move on from their ' +
        'work because I knew that if YOLANDA06 wanted to be successful, it needed another level of production. I went ' +
        'to an international-level factory that produces high-quality and maintains labor rights. But my friends ' +
        'from the ghetto still remain my friends, not to produce but to play street soccer.'
    const aboutText3 = '– Lisa ZC, founder and'
    const aboutText4 = 'creative director of YOLANDA06'

    return (
        <section className="about">
            <div className="about__container">
                <p className="about__text">
                    <span className="about__text-paragraph text">{aboutText1}</span>
                    <span className="about__text-paragraph text">{aboutText2}</span>
                    <span className="about__text-paragraph about__text-comment">
                        <span className="no-newline text">{aboutText3}</span> <span className="no-newline text">{aboutText4}</span>
                    </span>
                </p>
                <div className="about__img">
                    <img src={lisaZC} alt="" draggable="false" />
                </div>
            </div>
        </section>
    )
}

export default LisaZc
