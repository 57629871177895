import { motion, useTransform, useScroll } from 'framer-motion'

import { useRef } from 'react'

import yolandaGif from '../assets/yolanda-gif.gif'
import people from '../assets/gallery/gallery-4@1x.jpg'
import manWithPhoto from '../assets/gallery/gallery-6@1x.jpg'
import bg from '../assets/hero/hero-bg@1x.jpg'
import logoPortrait from '../assets/hero/hero-logo-short.png'
import photo from '../assets/random/zc-photo4@1x.jpg'
import hand1 from '../assets/hand-street.png'
import hand2 from '../assets/hand-woman.png'

import LookBook from '../sections/look-book/LookBook'
import LineSheet from '../sections/line-sheet/LineSheet'
import LisaZc from '../sections/about/LisaZC'
import Contacts from '../sections/contacts/Contacts'
import LisaSK from '../sections/about/LisaSK'
const VerticalView = ({ isPortrait }) => {
    const movingSectionRef = useRef(null)
    const heroImgRef = useRef(null)
    const { scrollYProgress } = useScroll({
        target: movingSectionRef,
    })

    const screenWidth = window.screen.width
    const screenHeight = window.screen.height
    const initialImageHeight = 1014
    const initialImageWidth = 1526
    // т.к. на андройде view port расширяется за счет скрытия поисковой строки при скролле
    // используется примерное значение чтобы не было белого пространства
    // 95% = 100% - 3%
    const hiddenImageWidthInPercent = 95 - (screenWidth / (initialImageWidth * (screenHeight / initialImageHeight)) * 100)

    const x = useTransform(
        scrollYProgress,
        [0, 1],
        ['0%', `-${hiddenImageWidthInPercent}%`]
    )

    return (
        <>
            <section
                className="vertical-hero"
                id="hero"
                ref={movingSectionRef}
            >
                <div className="vertical-hero__inner">
                    <motion.div style={{ x }} className="vertical-hero__moving-block">
                        <div className="vertical-hero__background">
                            <img
                                ref={heroImgRef}
                                className="vertical-hero__background-picture"
                                src={bg}
                                alt="background"
                                draggable="false"
                            />
                            <picture>
                                <source srcSet={logoPortrait}/>
                                <img
                                    className="vertical-hero__background-logo"
                                    src={logoPortrait}
                                    alt="logo"
                                    draggable="false"
                                />
                            </picture>
                        </div>
                    </motion.div>
                </div>
            </section>

            <section className="portrait-screen__body">
                <section className="portrait-screen__lisa">
                    <img
                        className="portrait-screen__lisa-img"
                        src={yolandaGif}
                        alt="yolanda gif"
                        draggable="false"
                    />
                </section>
                <section id="look-book" className="look-book-wrap screen-height">
                    <LookBook isPortrait={isPortrait} />
                </section>
                <section className="portrait-screen__lisa-photo">
                    <img
                        className="portrait-screen__lisa-photo-img"
                        src={manWithPhoto}
                        alt="man with photo"
                        draggable="false"
                    />
                </section>
                <section id="line-sheet" className="screen-height">
                    <LineSheet isPortrait={isPortrait} />
                </section>
                <section className="portrait-screen__people">
                    <img
                        className="portrait-screen__people-img"
                        src={people}
                        alt="people in museum"
                        draggable="false"
                    />
                </section>
                <section id="lisa-zc">
                    <LisaZc />
                </section>
                <section className="portrait-screen__hands auto-height">
                    <img
                        className="portrait-screen__hands_first"
                        src={hand1}
                        alt="people in museum"
                        draggable="false"
                    />
                    <img
                        className="portrait-screen__hands_second"
                        src={hand2}
                        alt="people in museum"
                        draggable="false"
                    />
                </section>
                <section className="portrait-screen__photo">
                    <img
                        className="portrait-screen__photo-img"
                        src={photo}
                        alt="people in museum"
                        draggable="false"
                    />
                </section>
                <section id="lisa-sk">
                    <LisaSK />
                </section>
                <section id="contacts" className="screen-height">
                    <Contacts isPortrait={isPortrait}/>
                </section>
            </section>
        </>
    )
}

export default VerticalView
