const SubmitInputSvg = () => {
    return (
        <svg
            viewBox="0 0 75 75"
            overflow="visible"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                id="d"
                fill="transparent"
                d="M92.825 51.725c2.823 11.774 3.732 18.047-2.47 26.36C80.474 89.027 62.054 94 45.89 94c-11.797 0-26.184-4.974-34.089-13.429-4.262-3.705-8.214-11.592-9.88-15.915-2.585-6.7-1.977-10.942-1.483-19.894C1.92 31.83 9.773 28.12 15.753 19.894 26.623 9.45 34.941 0 51.325 0 63.182.995 64.04 3.64 73.063 13.429c8.18 8.874 16.303 23.873 19.762 38.296Z"
                opacity=".5"
            />
            <text
                dy={-1}
                style={{fontSize: '18px', fill: '#009640', fontWeight: 'bold', fontFamily:'Helvetica NCB, sans-serif', textTransform:'uppercase'}}
            >
                <textPath href="#d">
                    SUBMIT SUBMIT SUBMIT SUBMIT SUBMIT SUBMIT SUBMIT SUBMIT SUBMIT SUBMIT SUBMIT SUBMIT
                </textPath>
            </text>
        </svg>
    )
}

export default SubmitInputSvg
