import lisaSK from '../../assets/lisa-sk/lisa-sk.png'

const LisaSK = () => {
    const aboutSKText = 'YOLANDA06 was born out of a desire to express emotions and feelings: from ' +
        'gratitude and friendliness to desperation and hesitation. It was taking its shape while ' +
        'Lisa and I were going through various fortunate and unfortunate events, coming to certain ' +
        'conclusions and visions of the world and circumstances surrounding us. Pieces of what we experienced' +
        ' together and separately fused and formed what now is YOLANDA06.'


    const aboutSKText2 = 'YOLANDA06 is a tribute to friendship, imagination, and freedom of expression. ' +
        'It is about people and for people. It is about the irony of fate. It is a brand.'

    const aboutSKText3 = 'Every piece of clothing, every drawing, our social media, and the website ' +
        'you’re currently looking at have the characteristics of YOLANDA06: artistic, coherently chaotic, ' +
        'and unusual to the eye. But be sure, every detail here has its purpose and tells the story we aim ' +
        'to continue narrating through what we create.'
    const aboutSKText4 = '– Lisa SK, co-founder'
    const aboutSKText5 = 'and CEO of YOLANDA06'

    return (
        <>
            <section className="about">
                <div className="about__container">
                    <p className="about__text">
                        <p className="about__text-paragraph text">{aboutSKText}</p>
                        <p className="about__text-paragraph text">{aboutSKText2}</p>
                        <p className="about__text-paragraph text">{aboutSKText3}</p>
                        <span className="about__text-paragraph about__text-comment">
                            <p className="no-newline text">{aboutSKText4}</p>
                            <p className="no-newline text">{aboutSKText5}</p>
                        </span>
                    </p>
                    <div className="about__img">
                        <img src={lisaSK} alt="" draggable="false" />
                    </div>
                </div>
            </section>
        </>

    )
}

export default LisaSK
