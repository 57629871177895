import { useState } from 'react'

import SubmitInputSvg from '../../components/app-svg/SubmitInputSvg'

import blob from '../../assets/line-sheet/line-sheet-trempel.png'
import face from '../../assets/line-sheet/line-sheet-face.png'
import inputBg1 from '../../assets/line-sheet/input-1.png'
import inputBg2 from '../../assets/line-sheet/input-2.png'
import inputBg3 from '../../assets/line-sheet/input-3.png'

const LineSheet = ({ isPortrait }) => {
    const data = [
        {
            image: inputBg1,
            input: {
                type: 'name',
                isRequired: true,
                placeholder: 'Type your name...',
            },
        },
        {
            image: inputBg2,
            input: {
                type: 'email',
                isRequired: true,
                placeholder: 'Type your email...',
            },
        },
        {
            image: inputBg3,
            input: {
                type: 'store',
                isRequired: false,
                placeholder: 'Type your store...',
            },
        },
    ]
    const [formState, setFormState] = useState(
        {
            name: '',
            email: '',
            store: '',
        }
    )

    const handleChange = (event) => {
        const inputName = event.target.getAttribute('data-name')
        setFormState({
            ...formState,
            [inputName]: event.target.value,
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        fetch("/msendm.php", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formState)
        })
            .then(response => {
                if (response.ok) {
                    alert("Message sent successfully!")
                    return response.text()
                } else {
                    alert("Message sending error. Please contact us by email contact@yolanda06.com")
                    throw new Error('Request failed')
                }
            })
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <section className="line-sheet">
            <form className="line-sheet__form form" onSubmit={handleSubmit}>
                {data.map((item, index) => (
                    <figure className={`form__item form__item_${item.input.type}`} key={item.input.type}>
                        {index === 0
                            && <>
                               <h2 className="form__title title helvetica-font">Linesheet</h2>
                                <picture className="line-sheet__trempel">
                                    <source srcSet={blob}/>
                                    <img src={blob} alt="trempel" draggable="false"/>
                                </picture>
                            </>
                        }
                        <img className="form__item-image" src={item.image} alt="input background" />
                        <input
                            className="form__item-input"
                            value={formState[item.input.type]}
                            data-name={item.input.type}
                            type={item.input.type}
                            required={item.input.isRequired}
                            placeholder={item.input.placeholder}
                            autoComplete={`new-${item.input.type}`}
                            onChange={handleChange}
                        />
                    </figure>
                ))}
                <button className="form__submit">
                    <SubmitInputSvg />
                </button>
            </form>
            <div className="line-sheet__face">
                <img className="line-sheet__face-img" src={face} alt="" draggable="false"/>
            </div>
        </section>
    )
}

export default LineSheet
