import {useEffect, useRef, useState} from 'react'

const AppSelect = ({ options }) => {
    const [selectedOption, setSelectedOption] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const selectRef = useRef(null)

    const handleSelectOption = (option) => {
        setSelectedOption(option.email)
        closeSelect()
    }

    const closeSelect = () => {
        setIsOpen(false)
    }

    const handleClickOutside = (event) => {
        if (selectRef.current && !selectRef.current.contains(event.target)) {
            closeSelect()
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside)

        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [])

    return (
        <div ref={selectRef} className="app-select">
            <span
                className={`app-select__button text ${selectedOption.length > 0 && 'filled'}`}
                onClick={() => setIsOpen(!isOpen)}
            >
                {selectedOption}
            </span>
            {isOpen && (
                <ul className="app-select__list">
                    {options.map((option) => (
                        <li
                            className="app-select__list-item text"
                            key={option.value}
                            onClick={() => handleSelectOption(option)}
                        >
                            {option.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

export default AppSelect
