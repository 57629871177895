import { useEffect, useState } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Mousewheel, FreeMode, Scrollbar } from 'swiper/modules'
import 'swiper/css'

import './index.scss'

import Hero from './sections/hero/Hero'
import Gallery from './sections/gallery/Gallery'
import LookBook from './sections/look-book/LookBook'
import LineSheet from './sections/line-sheet/LineSheet'
import LisaZc from './sections/about/LisaZC'
import Random from './sections/random/Random'
import LisaSK from './sections/about/LisaSK'
import Contacts from './sections/contacts/Contacts'

import AppSidebar from './components/app-sidebar/AppSidebar'

import VerticalView from './views/VerticalView'
import AppNavbar from './components/app-navbar/AppNavbar'
import AppPreloader from './components/app-preloader/AppPreloader'

function App() {
    const [isLoading, setIsLoading] = useState(true)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [isPortrait, setIsPortrait] = useState(false)
    const [activeSlide, setActiveSlide] = useState('hero')
    const [swiper, setSwiper] = useState(null)

    const setActiveSlideId = () => {
        const currentSlideId = swiper.slides[swiper.activeIndex].id

        if (currentSlideId !== '') {
            setActiveSlide(currentSlideId)
        }
    }

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])
    useEffect(() => {
        //TODO: оптимизировать
        setIsPortrait(window.matchMedia("(orientation: portrait)").matches)
    }, [windowWidth])

    // запуск прелоадера при инициализации и повороте экрана
    useEffect(() => {
        setIsLoading(true)

        const timer = setTimeout(() => {
            setIsLoading(false)
        }, 2500)

        return () => clearTimeout(timer)
    }, [isPortrait])

  return (
      <>
          {isLoading && <AppPreloader />}
          {isPortrait
              ? <AppNavbar />
              : <AppSidebar
                  activeSlide={activeSlide}
                  swiper={swiper}
              />
          }
          {isPortrait
              ? <VerticalView isPortrait={isPortrait} />
              : <Swiper
                  modules={[Mousewheel, FreeMode, Scrollbar]}
                  spaceBetween={50}
                  slidesPerView='auto'
                  mousewheel={true}
                  freeMode={{
                      enabled: true,
                      sticky: false,
                      momentumBounce: false,
                  }}
                  resistanceRatio={0}
                  simulateTouch={false}
                  direction={isPortrait ? 'vertical' : 'horizontal'}
                  onSlideChange={setActiveSlideId}
                  onSwiper={(swiper) => setSwiper(swiper)}
              >
                  <SwiperSlide id="hero">
                      <Hero />
                  </SwiperSlide>
                  <SwiperSlide className="auto-width">
                      <Gallery />
                  </SwiperSlide>
                  <SwiperSlide id="look-book" className="look-book-wrap">
                      <LookBook isPortrait={isPortrait} />
                  </SwiperSlide>
                  <SwiperSlide id="line-sheet">
                      <LineSheet isPortrait={isPortrait} />
                  </SwiperSlide>
                  <SwiperSlide id="lisa-zc">
                      <LisaZc />
                  </SwiperSlide>
                  <SwiperSlide className="auto-width">
                      <Random />
                  </SwiperSlide>
                  <SwiperSlide id="lisa-sk">
                      <LisaSK />
                  </SwiperSlide>
                  <SwiperSlide id="contacts">
                      <Contacts isPortrait={isPortrait} />
                  </SwiperSlide>
              </Swiper>
          }
      </>
  )
}

export default App
