import handStreet from '../../assets/random/hand-street.png'
import zcPhoto from '../../assets/random/zc-photo.png'
import zcPhoto3 from '../../assets/random/zc-photo3.png'
import preview1 from '../../assets/random/slide1.jpg'
import preview2 from '../../assets/random/slide2.png'
import preview3 from '../../assets/random/slide3.png'
import sliderMain from '../../assets/random/slide-main@1x.jpg'
import zcPhoto4 from '../../assets/random/zc-photo4@1x.jpg'
import hands from '../../assets/random/zc-hands.png'

const Random = () => {
    return (
         <section className="random">
             <div className="random__image random__image_first">
                 <img
                    src={handStreet}
                    alt=""
                    draggable="false"
                />
             </div>
            <div className="random__image random__image_second">
                <img
                    src={zcPhoto}
                    alt=""
                    draggable="false"
                />
            </div>
             <div className="random__image random__image_third">
                 <img
                    src={zcPhoto3}
                    alt=""
                    draggable="false"
                />
             </div>
            <section className="random__slider">
                <div className="random__slider-preview">
                    <img
                        className="random__image random__slider-preview_first"
                        src={preview1}
                        alt=""
                        draggable="false"
                    />
                    <img
                        className="random__image random__slider-preview_second"
                        src={preview2}
                        alt=""
                        draggable="false"
                    />
                    <img
                        className="random__image random__slider-preview_third"
                        src={preview3}
                        alt=""
                        draggable="false"
                    />
                </div>
                <img
                    className="random__image random__slider_active"
                    src={sliderMain}
                    alt=""
                    draggable="false"
                />
            </section>
             <div className="random__image random__image_last" style={{position: "relative"}}>
                 <div className="random__image_last-image">
                     <img
                         src={zcPhoto4}
                         alt=""
                         draggable="false"
                     />
                 </div>
                 <div className="random__image random__image-hands">
                     <img
                         src={hands}
                         alt=""
                         draggable="false"
                     />
                 </div>
             </div>
        </section>
    )
}

export default Random
