import leftEye from '../../assets/look-book/eye-left.png'
import rightEye from '../../assets/look-book/eye-right.png'
import mouth from '../../assets/look-book/mouth.png'
import leftHand from '../../assets/look-book/left-hand.svg'
import rightHand from '../../assets/look-book/right-hand.svg'

import lookBook from '../../assets/YOLANDA06lookbook.pdf'

const LookBook = ({ isPortrait }) => {
    const text = 'It is the first and limited edition collection. It addresses specific ' +
        'cultural issues such as views on beauty, gender, and silence through the designs and ' +
        'is inspired by accidents where an “ANTI-“ attitude enveloped society.'

    return (
        <section className="look-book">
            <p className="composition__text text">{text}</p>
            {isPortrait && <h2 className="look-book__title title helvetica-font">lookbook</h2>}
            <div className="look-book__composition composition">
                {!isPortrait && <h2 className="composition__title title helvetica-font">lookbook</h2>}
                <img className="composition__left-eye" src={leftEye} alt="composition eye" draggable="false" />
                <img className="composition__right-eye" src={rightEye} alt="composition eye" draggable="false" />
                <div className="composition__mouth">
                    <img src={mouth} alt="composition mouth" draggable="false" />
                    <a
                        className="composition__mouth-btn"
                        href={lookBook}
                        download="YOLANDA06lookbook.pdf"
                    >
                        download
                    </a>
                </div>
            </div>
            <img
                className="look-book__hand look-book__hand_left"
                src={leftHand}
                alt="left hand"
                draggable="false"
            />
            <img
                className="look-book__hand look-book__hand_right"
                src={rightHand}
                alt="right hand"
                draggable="false"
            />
        </section>
    )
}

export default LookBook
