import bg from '../../assets/hero/hero-bg@1x.jpg'
import logo from '../../assets/hero/hero-logo.png'
import logoPortrait from '../../assets/hero/hero-logo-short.png'

const Hero = () => {
    return (
        <section className="hero helvetica-font">
            <div className="hero__inner">
                <div className="hero__background">
                    <img
                        className="hero__background-picture"
                        src={bg}
                        alt="background"
                        draggable="false"
                    />
                    <picture>
                        <source
                            media="(orientation: portrait)"
                            srcSet={logoPortrait}
                        />
                        <img
                            className="hero__background-logo"
                            src={logo}
                            alt="logo"
                            draggable="false"
                        />
                    </picture>
                </div>
            </div>
        </section>
    )
}

export default Hero
