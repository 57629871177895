
const AppPreloader = () => {
    return (
        <section className="app-preloader">
            {/*<span className="app-preloader__ticker app-preloader__ticker_first no-newline">
                YOLANDA 06 YOLANDA 06 YOLANDA 06 YOLANDA 06 YOLANDA 06
            </span>
            <span className="app-preloader__ticker app-preloader__ticker_second no-newline">
                YOLANDA 06 YOLANDA 06 YOLANDA 06 YOLANDA 06 YOLANDA 06
            </span>*/}
            <svg width="317" height="430" viewBox="0 0 317 430" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className="app-preloader__iris" d="M208.87 242.41L204.2 204.05C204.2 204.05 189.82 180.7 216.7 204.05C254.52 236.9 251.42 270.9 250.55 274.78C249.68 278.66 225.32 312.24 225.32 312.24C225.32 312.24 200.32 344.57 194.72 342.41C189.12 340.25 172.74 333.79 172.74 333.79L175.33 293.27C175.33 293.27 187.4 287.67 170.59 279.05C153.78 270.43 122.75 260.95 121.88 262.24C121.01 263.53 88.69 223.02 114.98 279.05C141.27 335.08 156.36 351.03 162.39 359.22C168.42 367.41 188.25 372.15 193.86 374.31C199.47 376.47 219.72 392.41 230.07 356.21C240.42 320.01 250.97 270.84 250.97 270.84C250.97 270.84 232.77 384.23 191.82 357.94C150.87 331.65 127.7 304.83 127.7 304.83" stroke="#009FE3" strokeWidth="20" strokeMiterlimit="10"/>
                <path className="app-preloader__border" d="M222.39 292.09C222.39 292.09 193.08 302.43 194.37 302.43C195.66 302.43 181.01 313.64 181.01 313.64L170.67 322.09C170.67 322.09 166.36 331.75 157.41 337.78C148.46 343.81 152.13 336.49 152.56 330.45C152.99 324.41 181.44 302.43 191.35 295.97C201.26 289.51 185.75 294.68 209.02 281.31C232.29 267.94 236.18 299.84 236.18 299.84L227.99 305.87L206.01 311.47C206.01 311.47 168.94 314.49 151.7 305.44C134.46 296.39 147.82 291.65 147.82 291.65L131.87 276.13L120.66 282.6L128.42 293.38H136.18L142.21 285.62L148.24 277L146.52 267.52L144.8 254.16L130.58 242.09L126.7 245.97L122.39 258.04C122.39 258.04 122.39 280.02 124.98 277.87C127.57 275.72 128 278.73 130.15 273.99C131.14 271.8 133.33 260.16 137.48 244.31C142.32 225.82 149.81 201.61 161.19 180.03C182.31 139.94 188.35 149.86 212.48 139.08C236.61 128.3 259.89 140.8 278 155.46C296.11 170.12 306.56 229.6 306.02 236.91C305.58 242.76 307.31 327.88 278 368.82C248.69 409.76 232.31 417.96 207.74 419.25C183.17 420.54 139.64 402.87 120.24 386.06C100.84 369.25 100.41 363.22 80.58 311.06C72.3 289.28 76.14 248.07 79.16 230.34C83.38 205.61 90.07 207.07 96.1 197.91C106.44 182.18 157.31 162.78 195.67 155.88C218.76 151.73 250.45 151.48 272.39 151.38C286.9 151.31 297.14 151.31 297.83 150.28C299.55 147.69 282.74 127.87 276.71 115.8C270.68 103.73 235.76 100.28 199.99 93.82C164.22 87.36 119.39 113.22 119.39 113.22C94.39 122.27 96.98 136.5 65.94 142.1C34.9 147.7 10.77 142.53 10.77 138.22C10.77 133.91 23.27 125.29 41.37 103.31C59.47 81.33 93.96 51.15 124.13 34.34C154.3 17.53 177.58 11.93 196.11 11.06C214.64 10.19 203.01 19.68 199.99 21.4C196.97 23.12 174.99 26.14 131.89 49.42C88.79 72.7 97.84 101.58 108.61 123.56C119.38 145.54 146.54 150.28 146.54 150.28L152.14 150.71C152.14 150.71 160.33 132.61 139.64 127.87C118.95 123.13 91.36 127.01 86.62 149.42C81.88 171.83 86.62 181.32 82.74 200.71C78.86 220.1 82.12 225.04 82.12 225.04" stroke="#1D1D1B" strokeWidth="20" strokeMiterlimit="10"/>
                <path className="app-preloader__pupil" d="M187.48 253.3C187.48 253.3 185.58 253.93 184.02 253.84C181.94 253.72 180.45 252.32 184.89 246.4C192.65 236.06 197.39 236.92 197.39 236.92L215.06 245.38L223.25 256.32V265.37L218.08 273.56L205.58 278.3L193.51 285.63H182L173.25 275.29L177.13 261.27L198.25 250.29L207.3 253.74L209.89 259.34L207.73 264.51L203.85 269.68L198.25 272.27C198.25 272.27 192.65 273.99 190.49 273.99C188.33 273.99 186.61 271.4 186.61 271.4L187.47 268.81L191.35 265.79" stroke="#1D1D1B" strokeWidth="20" strokeMiterlimit="10"/>
            </svg>
        </section>
    )
}

export default AppPreloader
