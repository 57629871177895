import Gallery1 from '../../assets/yolanda-gif.gif'
import Gallery2 from '../../assets/gallery/gallery-2@1x.jpg'
import Gallery3 from '../../assets/gallery/gallery-3@1x.jpg'
import Gallery4 from '../../assets/gallery/gallery-4@1x.jpg'
import Gallery5 from '../../assets/gallery/gallery-5@1x.jpg'
import Gallery6 from '../../assets/gallery/gallery-6@1x.jpg'

const Gallery = () => {
    return (
        <section className="gallery">
            <div className="gallery__image gallery__image_first">
                <img src={Gallery1} alt="" draggable="false" />
            </div>
            <div className="gallery__image gallery__image_second">
                <img className="" src={Gallery2} alt="" draggable="false" />
            </div>
            <div className="gallery__image gallery__image_third">
                <img src={Gallery3} alt="" draggable="false" />
            </div>
            <div className="gallery__image gallery__image_fourth">
                <img className="" src={Gallery4} alt="" draggable="false" />
            </div>
            <div className="gallery__image gallery__image_fifth">
                <img className="" src={Gallery5} alt="" draggable="false" />
            </div>
            <div className="gallery__image gallery__image_sixth">
                <img src={Gallery6} alt="" draggable="false" />
            </div>
        </section>
    )
}

export default Gallery
