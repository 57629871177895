import { useState } from 'react'
import eye from '../../assets/navbar-eye.png'

const AppNavbar = () => {
    const [isNavbarOpen, setIsNavbarOpen] = useState(false)
    const sidebarList = [
        {index: 1, name: 'Yolanda06', id: 'hero', scrollPosition: 'start'},
        {index: 2, name: 'LookBook', id: 'look-book', scrollPosition: 'center'},
        {index: 3, name: 'LineSheet', id: 'line-sheet', scrollPosition: 'center'},
        {index: 4, name: 'By Lisa ZC', id: 'lisa-zc', scrollPosition: 'end'},
        {index: 5, name: 'By Lisa SK', id: 'lisa-sk', scrollPosition: 'end'},
        {index: 6, name: 'Contact us', id: 'contacts', scrollPosition: 'center'},
    ]

    const closeNavbar = () => {
        setIsNavbarOpen(false)
    }
    const scrollToSection = (id, screenPosition) => {
        const section = document.getElementById(id)
        section.scrollIntoView({behavior: 'smooth', block: screenPosition, inline: 'nearest'});
    }

    return (
        <>
            <button
                className="navbar-trigger helvetica-font"
                onClick={() => setIsNavbarOpen(true)}
            >
                <img src={eye} alt="eye" draggable="false" />
            </button>
            <nav
                className={`app-navbar helvetica-font ${isNavbarOpen && 'open'}`}
                onClick={closeNavbar}
            >
                <ul className="app-navbar__list ">
                    {sidebarList.map(link => (
                        <li
                            className="app-navbar__item title"
                            key={link.id}
                            onClick={() => scrollToSection(link.id, link.scrollPosition)}
                        >
                            {link.name}
                        </li>
                    ))}
                </ul>
            </nav>
        </>
    )
}

export default AppNavbar
