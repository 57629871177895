const AppSidebar = ({ activeSlide, swiper }) => {
    const sidebarList = [
        {index: 1, name: 'Yolanda06', id: 'hero'},
        {index: 2, name: 'LookBook', id: 'look-book'},
        {index: 3, name: 'LineSheet', id: 'line-sheet'},
        {index: 4, name: 'By Lisa ZC', id: 'lisa-zc'},
        {index: 5, name: 'By Lisa SK', id: 'lisa-sk'},
        {index: 6, name: 'Contact us', id: 'contacts'},
    ]

    const goToSlide = (currentId) => {
        const currentSlide = swiper.slides.find((slide) => slide.id === currentId)
        const currentIndex = swiper.slides.indexOf(currentSlide)
        swiper.slideTo(currentIndex)
    }

    return (
        <nav className="app-sidebar helvetica-font">
            <ul className="app-sidebar__list">
                {sidebarList.map(link => (
                    <li className="app-sidebar__list-item item" key={link.index}>
                        <button
                            className={`helvetica-font item__link ${activeSlide === link.id && 'item__link_active'}`}
                            onClick={() => goToSlide(link.id)}
                        >
                            {link.index}
                        </button>
                        <div className="item__overlay">
                            <span className="item__overlay-text">{link.name}</span>
                        </div>
                    </li>
                ))}
            </ul>
        </nav>
    )
}

export default AppSidebar
