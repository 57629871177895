import AppSelect from '../../components/app-select/AppSelect'

import contacts1 from '../../assets/contacts/contacts1.png'
import contacts2 from '../../assets/contacts/contacts2.png'

const Contacts = ({ isPortrait }) => {
    const options = [
        { value: 'kick', label: '- kicks with the left foot', email: 'lisazecchinato@yolanda06.com' },
        { value: 'hypno', label: '- hypnotizes rivals', email: 'lisasokolovskaya@yolanda06.com' },
        { value: 'fight', label: '- you would like to stand next to you in a fight', email: 'contact@yolanda06.com' },
    ]
    const lisaSK = 'lisasokolovskaya@yolanda06.com'
    const lisaZK = 'lisazecchinato@yolanda06.com'
    const contact = 'contact@yolanda06.com'
    const text = 'Lisa and Lisa became friends in Boston, now they work together at YOLANDA06.' +
        'You can contact any Lisa'

    return (
        <div className="contacts-wrap">
            {isPortrait &&
                <h2 className="contacts__title title helvetica-font">Contact us</h2>
            }
            <section className="contacts">
                <div className="contacts__emails">
                    <a className="contacts__link text" href={`mailto:${lisaSK}`}>{lisaSK}</a>
                    <a className="contacts__link text" href={`mailto:${lisaZK}`}>{lisaZK}</a>
                </div>
                <div className="contacts__description">
                    <p className="contacts__text text">
                        {text} <span className="no-newline"> at <a className="contacts__link text" href={`mailto:${contact}`}>{contact}</a> or </span>
                    </p>
                    <div className="contacts__select">
                        <p className="contacts__text contacts__select-pre text">choose the Lisa that&nbsp;</p>
                        <AppSelect options={options} />
                    </div>
                </div>
                <div className="contacts__images">
                    <div className="contacts__images_first">
                        <img src={contacts1} alt="" draggable="false" />
                    </div>
                    <div className="contacts__images_second">
                        <img src={contacts2} alt="" draggable="false" />
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Contacts
